import React, { useState, useEffect, useRef } from 'react';
import styles from './landing.scss';
import global from '../../global/global.scss';
import CityScene from '../../CityScene';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import LoadingIndicator from '../../components/LoadingIndicator';
import { gsap } from 'gsap';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

const Landing = () => {
    const [loading, setLoading] = useState(true);
    const [model, setModel] = useState(null);
    const menu = useRef(null);

    useEffect(() => {
        const loader = new GLTFLoader();
        loader.load(
            '/assets/models/beldner.glb',
            (gltf) => {
                setModel(gltf.scene);
                setLoading(false);
            },
            undefined,
            (error) => {
                console.error('An error occurred while loading the model', error);
                setLoading(false);
            }
        );
    }, []);

    const menuItems = [
        { label: 'Home', command: () => window.scrollTo(0, 0) },
        { label: 'About', command: () => console.log('Navigate to Skillset') },
        { label: 'Contact', command: () => console.log('Navigate to Contact') },
    ];

    return (
        <>
            {loading ? (
                <div className={styles['loading-container']}>
                    <LoadingIndicator />
                </div>
            ) : (
                <>
                    <header className={'header'}>
                        <div className={'logo'}>nam.</div>
                        <div className={styles['menu-container']}>
                            <Menu model={menuItems} popup ref={menu} className={global['menu']} />
                            <Button
                                label=""
                                icon="pi pi-bars"
                                onClick={(event) => menu.current.toggle(event)}
                                className={global['menu-button']}
                            />
                        </div>
                    </header>
                    <main>
                        <Card className={'landing-container'}>
                            <h1>IN DATA WE TRUST.</h1>
                        </Card>
                        <CityScene />
                    </main>
                </>
            )}
        </>
    );
};

export default Landing;
