import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const ModelScene = () => {
    const mountRef = useRef(null);

    useEffect(() => {
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.setClearColor(0x000000);
        mountRef.current.appendChild(renderer.domElement);

        const ambientLight = new THREE.AmbientLight(0x404040, 2);
        scene.add(ambientLight);

        const directionalLight = new THREE.DirectionalLight(0xffffff, 4);
        directionalLight.position.set(0, 50, 50);
        scene.add(directionalLight);

        let model;
        const loader = new GLTFLoader();
        loader.load(
            '/assets/models/city.glb',
            (gltf) => {
                model = gltf.scene;
                scene.add(model);

                const box = new THREE.Box3().setFromObject(model);
                const modelCenter = new THREE.Vector3();
                box.getCenter(modelCenter);

                camera.position.set(0, 300, 500);
                startCameraIntro(modelCenter);

                if (gltf.animations.length > 0) {
                    const mixer = new THREE.AnimationMixer(model);
                    gltf.animations.forEach((clip) => {
                        mixer.clipAction(clip).play();
                    });

                    const clock = new THREE.Clock();
                    function animateModel() {
                        const delta = clock.getDelta();
                        mixer.update(delta);
                        requestAnimationFrame(animateModel);
                    }
                    animateModel();
                }
            },
            undefined,
            (error) => {
                console.error('Error loading model:', error);
            }
        );

        const controls = new OrbitControls(camera, renderer.domElement);
        controls.enableDamping = true;
        controls.dampingFactor = 0.25;
        controls.screenSpacePanning = true;
        controls.maxPolarAngle = Math.PI / 2;

        const interactionData = {
            startX: 0,
            startY: 0,
            deltaX: 0,
            deltaY: 0,
            isInteracting: false,
        };

        // Global Event Handlers
        const onTouchStart = (event) => {
            interactionData.startX = event.touches[0].clientX;
            interactionData.startY = event.touches[0].clientY;
            interactionData.isInteracting = true;
        };

        const onTouchMove = (event) => {
            if (interactionData.isInteracting) {
                interactionData.deltaX = event.touches[0].clientX - interactionData.startX;
                interactionData.deltaY = event.touches[0].clientY - interactionData.startY;
            }
        };

        const onTouchEnd = () => {
            interactionData.isInteracting = false;
        };

        const onMouseDown = (event) => {
            interactionData.startX = event.clientX;
            interactionData.startY = event.clientY;
            interactionData.isInteracting = true;
        };

        const onMouseMove = (event) => {
            if (interactionData.isInteracting) {
                interactionData.deltaX = event.clientX - interactionData.startX;
                interactionData.deltaY = event.clientY - interactionData.startY;
            }
        };

        const onMouseUp = () => {
            interactionData.isInteracting = false;
        };

        // Attach Event Listeners
        renderer.domElement.addEventListener('touchstart', onTouchStart);
        renderer.domElement.addEventListener('touchmove', onTouchMove);
        renderer.domElement.addEventListener('touchend', onTouchEnd);

        renderer.domElement.addEventListener('mousedown', onMouseDown);
        renderer.domElement.addEventListener('mousemove', onMouseMove);
        renderer.domElement.addEventListener('mouseup', onMouseUp);

        const startCameraIntro = (modelCenter) => {
            const clock = new THREE.Clock();
            const radius = 200;
            const minHeight = 250;
            let angle = 100;
            let height = 1200;
            let introComplete = false;

            function animateIntro() {
                const delta = clock.getDelta();

                if (!introComplete) {
                    angle += delta * 0.1;
                    height -= delta * 100;
                    height = Math.max(height, minHeight);
                    const xOffset = 100 * Math.sin(angle);
                    camera.position.set(
                        radius * Math.cos(angle) + xOffset,
                        height,
                        radius * Math.sin(angle)
                    );
                    camera.lookAt(modelCenter);

                    if (height <= minHeight) {
                        introComplete = true;
                        controls.enabled = true; // Enable user controls
                    }
                } else {
                    angle += delta * 0.1 + interactionData.deltaX * 0.0005; // Adjust angle
                    const xOffset = 100 * Math.sin(angle);
                    const smoothDeltaY = interactionData.deltaY * 0.01;
                    camera.position.set(
                        radius * Math.cos(angle) + xOffset,
                        minHeight - smoothDeltaY, // Adjust height
                        radius * Math.sin(angle)
                    );
                    camera.lookAt(modelCenter);

                    // Reset deltas for smooth transition
                    interactionData.deltaX *= 0.95;
                    interactionData.deltaY *= 0.95;
                }

                controls.target.copy(modelCenter);
                controls.update();
                renderer.render(scene, camera);
                requestAnimationFrame(animateIntro);
            }

            animateIntro();
        };

        const handleResize = () => {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            renderer.domElement.removeEventListener('touchstart', onTouchStart);
            renderer.domElement.removeEventListener('touchmove', onTouchMove);
            renderer.domElement.removeEventListener('touchend', onTouchEnd);
            renderer.domElement.removeEventListener('mousedown', onMouseDown);
            renderer.domElement.removeEventListener('mousemove', onMouseMove);
            renderer.domElement.removeEventListener('mouseup', onMouseUp);
            mountRef.current.removeChild(renderer.domElement);
        };
    }, []);

    return <div ref={mountRef} className={'container'} />;
};

export default ModelScene;
